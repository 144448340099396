import { createContext, PropsWithChildren, useEffect, useState } from "react";
import { Notification } from "./lib/types/generic";
import { generateUuid, log } from "./lib/utils/generic";

export const defaultBreakpoints = {
  xs: false,
  sm: false,
  md: false,
  lg: false,
  xl: false,
  sl: false,
  ul: false,
};

interface GlobalContextType {
  loading: boolean;
  setLoading: (loading: boolean) => void;
  breakpoints: typeof defaultBreakpoints;
  setBreakpoints: (breakpoints: typeof defaultBreakpoints) => void;
  notifications: Notification[];
  setNotifications: (notifications: Notification[]) => void;
  pushNotification: (notification: Notification) => void;
  spliceNotification: (notification: Notification) => void;
}

export const GlobalContext = createContext<GlobalContextType>(
  {} as GlobalContextType
);

export default function GlobalContextProvider(props: PropsWithChildren<{}>) {
  const { children } = props;

  const [loading, setLoading] = useState(false);
  const [breakpoints, setBreakpoints] = useState(defaultBreakpoints);
  const [notifications, setNotifications] = useState<Notification[]>([]);

  const spliceNotification = (notification: Notification) => {
    setNotifications((notifications) => {
      const index = notifications.indexOf(notification);
      if (index < 0) return notifications;
      const notificationsCopy = [...notifications];
      notificationsCopy.splice(index, 1);
      return notificationsCopy;
    });
  };

  const pushNotification = (notification: Notification) => {
    setNotifications((notifications) => [
      ...notifications,
      { ...notification, key: generateUuid() },
    ]);
  };

  const onResize = () => {
    const viewWidth = document.body.clientWidth;
    const breakpoints = {
      xs: viewWidth <= 0,
      sm: viewWidth <= 600,
      md: viewWidth <= 960,
      lg: viewWidth <= 1280,
      xl: viewWidth <= 1780,
      sl: viewWidth <= 2560,
      ul: viewWidth <= 3840,
    };
    log(breakpoints);
    setBreakpoints(breakpoints);
  };

  useEffect(() => {
    onResize();
    window.addEventListener("resize", onResize);
    return window.addEventListener("resize", onResize);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <GlobalContext.Provider
      value={{
        loading,
        setLoading,
        breakpoints,
        setBreakpoints,
        notifications,
        setNotifications,
        pushNotification,
        spliceNotification,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
}
