import React from "react";

import panel from "../../media/images/panel.jpg";

export default function Panel() {
  return (
    <div className="panel-wrapper">
      <img src={panel} alt="panel" className="panel" />
    </div>
  );
}
