import {
  cloneElement,
  HTMLAttributeAnchorTarget,
  HTMLAttributeReferrerPolicy,
} from "react";
import { ReactElement } from "react";
import { CSSProps, UIColors } from "../../lib/types/generic";

interface Props extends CSSProps {
  color?: UIColors;
  variant?: "contained" | "outlined" | "flat";
  size?: "small" | "medium" | "large" | "extra-large";
  sizeOverride?: number;
  icon?: ReactElement;
  type?: "button" | "submit" | "reset";
  disabled?: boolean;
  form?: string;
  href?: string;
  openInNewTab?: boolean;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  target?: HTMLAttributeAnchorTarget;
  rel?: HTMLAttributeReferrerPolicy;
}

export default function IconButton(props: Props) {
  const {
    color = "black",
    variant = "contained",
    size = "medium",
    sizeOverride,

    icon,
    type = "button",
    disabled = false,
    form,
    href,
    openInNewTab,
    onClick,
    target,
    rel,

    style,
    className = "",
  } = props;

  let iconSize = {
    small: 40,
    medium: 48,
    large: 64,
    "extra-large": 96,
  }[size];

  if (sizeOverride != null) iconSize = sizeOverride;

  const nodes = (
    <button
      type={type}
      disabled={disabled}
      form={form}
      onClick={onClick}
      style={{
        ...style,
        minWidth: iconSize,
        minHeight: iconSize,
        width: iconSize,
        height: iconSize,
        lineHeight: 0,
      }}
      className={`icon ${
        disabled ? "disabled" : ""
      } ${color} ${size} ${variant} ${className}`}
    >
      {icon &&
        cloneElement(icon, {
          fontSize: iconSize,
        })}
    </button>
  );

  if (href == null || disabled) return nodes;
  return (
    <a
      href={href}
      {...(openInNewTab
        ? {
            target: "_blank",
            rel: "noopener noreferrer",
          }
        : {})}
      target={target}
      rel={rel}
    >
      {nodes}
    </a>
  );
}
