import { Config } from "./config";

const config: Config = {
  apiURL: "https://3q1hhw5ul2.execute-api.eu-west-2.amazonaws.com/dev",
  gaKey: "UA-216712514-1",
  hjid: 2780232,
  hsjv: 6,
};

export default config;
