import developmentConfig from "./development.config";
import productionConfig from "./production.config";

export interface Config {
  apiURL: string;
  gaKey: string;
  hjid: number;
  hsjv: number;
}

export const env = process.env.NODE_ENV || "development";
export const isDev = env === "development";

const config = {
  test: developmentConfig,
  development: developmentConfig,
  production: productionConfig,
};

export default config[env];
