import React, { useEffect, useState } from "react";
import Carousel, { ResponsiveType } from "react-multi-carousel";
import AnimateOnIntersect from "../../components/common/AnimateOnIntersect";

import apexLegends from "../../media/images/games/apex-legends/covers/cover_big.jpg";
import arkSurvivalEvolved from "../../media/images/games/ark-survival-evolved/covers/cover_big.jpg";
import battlefield2042 from "../../media/images/games/battlefield-2042/covers/cover_big.jpg";
import callOfDutyVanguard from "../../media/images/games/call-of-duty-vanguard/covers/cover_big.jpg";
import callOfDutyWarzone from "../../media/images/games/call-of-duty-warzone/covers/cover_big.jpg";
import dota2 from "../../media/images/games/dota-2/covers/cover_big.jpg";
import escapeFromTarkov from "../../media/images/games/escape-from-tarkov/covers/cover_big.jpg";
import fifa22 from "../../media/images/games/fifa-22/covers/cover_big.jpg";
import fortnite from "../../media/images/games/fortnite/covers/cover_big.jpg";
import forzaHorizon from "../../media/images/games/forza-horizon/covers/cover_big.jpg";
import goldWithYourFriends from "../../media/images/games/golf-with-your-friends/covers/cover_big.jpg";
import grandTheftAutoV from "../../media/images/games/grand-theft-auto-v/covers/cover_big.jpg";
import haloInfinite from "../../media/images/games/halo-infinite/covers/cover_big.jpg";
import hearthstone from "../../media/images/games/hearthstone/covers/cover_big.jpg";
import leagueOfLegends from "../../media/images/games/league-of-legends/covers/cover_big.jpg";
import minecraft from "../../media/images/games/minecraft/covers/cover_big.jpg";
import overwatch from "../../media/images/games/overwatch/covers/cover_big.jpg";
import pubgBattlegrounds from "../../media/images/games/pubg-battlegrounds/covers/cover_big.jpg";
import roblox from "../../media/images/games/roblox/covers/cover_big.jpg";
import rocketLeague from "../../media/images/games/rocket-league/covers/cover_big.jpg";
import rust from "../../media/images/games/rust/covers/cover_big.jpg";
import tomClancysRainbowSixSiege from "../../media/images/games/tom-clancys-rainbow-six-siege/covers/cover_big.jpg";
import valorant from "../../media/images/games/valorant/covers/cover_big.jpg";

const games = [
  { name: "apexLegends", title: "Apex Legends", image: apexLegends },
  {
    name: "arkSurvivalEvolved",
    title: "Ark: Survival Evolved",
    image: arkSurvivalEvolved,
  },
  {
    name: "battlefield2042",
    title: "Battlefield 2042",
    image: battlefield2042,
  },
  {
    name: "callOfDutyVanguard",
    title: "Call of Duty: Vanguard",
    image: callOfDutyVanguard,
  },
  {
    name: "callOfDutyWarzone",
    title: "Call of Duty: Warzone",
    image: callOfDutyWarzone,
  },
  { name: "dota2", title: "Dota 2", image: dota2 },
  {
    name: "escapeFromTarkov",
    title: "Escape from Tarkov",
    image: escapeFromTarkov,
  },
  { name: "fifa22", title: "FIFA 22", image: fifa22 },
  { name: "fortnite", title: "Fortnite", image: fortnite },
  { name: "forzaHorizon", title: "Forza Horizon", image: forzaHorizon },
  {
    name: "goldWithYourFriends",
    title: "Golf With Your Friends",
    image: goldWithYourFriends,
  },
  {
    name: "grandTheftAutoV",
    title: "Grand Theft Auto V",
    image: grandTheftAutoV,
  },
  { name: "haloInfinite", title: "Halo: Infinite", image: haloInfinite },
  { name: "hearthstone", title: "Hearthstone", image: hearthstone },
  {
    name: "leagueOfLegends",
    title: "League of Legends",
    image: leagueOfLegends,
  },
  {
    name: "minecraft",
    title: "Minecraft: Java & Bedrock Edition",
    image: minecraft,
  },
  { name: "overwatch", title: "Overwatch", image: overwatch },
  {
    name: "pubgBattlegrounds",
    title: "PUBG: Battlegrounds",
    image: pubgBattlegrounds,
  },
  { name: "roblox", title: "Roblox", image: roblox },
  { name: "rocketLeague", title: "Rocket League", image: rocketLeague },
  { name: "rust", title: "Rust", image: rust },
  {
    name: "tomClancysRainbowSixSiege",
    title: "Tom Clancy's Rainbow Six Siege",
    image: tomClancysRainbowSixSiege,
  },
  { name: "valorant", title: "Valorant", image: valorant },
];

const responsive: ResponsiveType = {
  xl: {
    breakpoint: { max: 9999, min: 1780 },
    items: 6,
  },
  lg: {
    breakpoint: { max: 1780, min: 1280 },
    items: 5,
  },
  md: {
    breakpoint: { max: 1280, min: 960 },
    items: 5,
  },
  sm: {
    breakpoint: { max: 960, min: 600 },
    items: 4,
  },
  xs: {
    breakpoint: { max: 600, min: 0 },
    items: 2,
  },
};

export default function SupportedGames() {
  const [autoPlay, setAutoPlay] = useState(false);

  useEffect(() => {
    setTimeout(() => setAutoPlay(true), 10);
  }, [setAutoPlay]);

  return (
    <div className="supported-games">
      <AnimateOnIntersect animation="fade">
        <p className="title">
          <span className="text-gradient">Games</span> that we support
        </p>
      </AnimateOnIntersect>
      <Carousel
        responsive={responsive}
        infinite
        draggable={false}
        swipeable={false}
        autoPlay={autoPlay}
        autoPlaySpeed={0}
        transitionDuration={3000}
        customTransition="all 3000ms linear"
        arrows={false}
      >
        {games.map(({ name, title, image }, i) => (
          <div className="item" key={`${name}-${i}`}>
            <img src={image} alt={title} className="cover" />
            <div className="title-wrapper">
              <p className="title">{title}</p>
            </div>
          </div>
        ))}
      </Carousel>
    </div>
  );
}
