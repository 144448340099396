import React, { ReactNode } from "react";
import { CSSProps } from "../../lib/types/generic";

interface Props extends CSSProps {
  top?: ReactNode;
  left?: ReactNode;
  right?: ReactNode;
}

export default function Bar(props: Props) {
  const { left, right, top, className = "", id, style } = props;

  return (
    <>
      <div className={`bar ${className}`} id={id} style={style}>
        {top && <div className="top">{top}</div>}
        <div className="group">
          {left && <div className="left">{left}</div>}
          {right && <div className="right">{right}</div>}
        </div>
      </div>
    </>
  );
}
