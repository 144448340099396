import React from "react";
import TopProgress from "./components/layout/TopProgress";
import GlobalContextProvider from "./GlobalContextProvider";
import Home from "./home/Home";
import ReactGA from "react-ga4";
import config from "./config/config";
import { hotjar } from "react-hotjar";

import "react-multi-carousel/lib/styles.css";

function Root() {
  ReactGA.initialize(config.gaKey);
  ReactGA.send("pageview");

  hotjar.initialize(config.hjid, config.hsjv);

  return (
    <GlobalContextProvider>
      <div id="portal-root" />
      <TopProgress />
      <div className="content-root">
        <Home />
      </div>
    </GlobalContextProvider>
  );
}

export default Root;
