import { useLayoutEffect, useState } from "react";

const useScrollPosition = () => {
  const [scrollPosition, setScrollPosition] = useState({
    x: window.scrollX,
    y: window.scrollY,
  });

  useLayoutEffect(() => {
    const onScroll = () => {
      setScrollPosition({ x: window.scrollX, y: window.scrollY });
    };

    onScroll();

    window.addEventListener("scroll", onScroll);

    return () => {
      window.removeEventListener("scroll", onScroll);
    };
  }, []);

  return scrollPosition;
};

export default useScrollPosition;
