import React, {
  Children,
  isValidElement,
  PropsWithChildren,
  useRef,
  useState,
} from "react";
import { cloneElement } from "react";
import { CSSTransition } from "react-transition-group";
import Intersection from "./Intersection";

export type IntersectionAnimation =
  | "slide-right"
  | "slide-left"
  | "slide-down"
  | "fade";

export type IntersectionAnimationProps = Props;

interface Props {
  animation?: IntersectionAnimation;
  transitionProperty?: string;
  transitionTimeout?: number;
  transitionEasing?: string;
}

const transitionProperties = {
  "slide-right": "transform",
  "slide-left": "transform",
  "slide-down": "transform",
  fade: "opacity",
};

const transitionTimeouts = {
  "slide-right": 1000,
  "slide-left": 1000,
  "slide-down": 1000,
  fade: 2000,
};

export default function AnimateOnIntersect(props: PropsWithChildren<Props>) {
  const {
    children,
    animation = "slide-right",
    transitionProperty = transitionProperties[animation],
    transitionTimeout = transitionTimeouts[animation],
    transitionEasing = "ease",
  } = props;

  const [intersected, setIntersected] = useState(false);

  const ref = useRef<HTMLElement | null>(null);

  return (
    <Intersection
      onIntersect={(entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting && entry.intersectionRatio > 0)
            setIntersected(true);
        });
      }}
      nodeRef={ref}
      useParent
    >
      <CSSTransition in={intersected} timeout={transitionTimeout}>
        {(state) =>
          Children.map(
            children,
            (child) =>
              isValidElement(child) &&
              cloneElement(child, {
                ...child.props,
                className: `${
                  child.props.className ?? ""
                } intersect-animation ${animation}`,
                style: {
                  ...(child.props.style ?? {}),
                  ...(state === "entering" || state === "exiting"
                    ? {
                        transition: `${transitionProperty} ${transitionTimeout}ms ${transitionEasing}`,
                      }
                    : {}),
                },
                ref,
              })
          )
        }
      </CSSTransition>
    </Intersection>
  );
}
