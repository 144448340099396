import React from "react";
import CircularBlur from "../../components/common/CircularBlur";
import Button from "../../components/form/Button";
import Bar from "./Bar";

import { IoArrowForward } from "react-icons/io5";
import AnimateOnIntersect from "../../components/common/AnimateOnIntersect";
import { applyUrl } from "../../lib/constants";

export default function DoNext() {
  return (
    <Bar
      className="do-next"
      top={
        <AnimateOnIntersect>
          <h3 className="title">
            What should I do <span className="text-gradient">next?</span>
          </h3>
        </AnimateOnIntersect>
      }
      left={
        <>
          <CircularBlur />

          <AnimateOnIntersect transitionTimeout={1250}>
            <p className="text">
              <span className="number">01 Apply</span>
              <br />
              <span>Submit your application - and we'll be in touch</span>
            </p>
          </AnimateOnIntersect>

          <AnimateOnIntersect transitionTimeout={1500}>
            <p className="text">
              <span className="number">02 Contact</span>
              <br />
              <span>If you have a question, email or DM us</span>
            </p>
          </AnimateOnIntersect>

          <AnimateOnIntersect transitionTimeout={1750}>
            <p className="text">
              <span className="number">03 Join</span>
              <br />
              <span>Explore our Discord Server and meet the Yakkr team</span>
            </p>
          </AnimateOnIntersect>
        </>
      }
      right={
        <>
          <AnimateOnIntersect animation="slide-left">
            <div className="button-wrapper">
              <Button
                endIcon={
                  <div>
                    <IoArrowForward />
                  </div>
                }
                color="white"
                size="large"
                iconSizeOverride={48}
                href={applyUrl}
                openInNewTab
              >
                Apply now
              </Button>
            </div>
          </AnimateOnIntersect>

          <AnimateOnIntersect animation="slide-left" transitionTimeout={1500}>
            <iframe
              className="discord-widget"
              title="Discord Widget"
              src="https://discord.com/widget?id=928594807335563285&theme=dark"
              width="350"
              height="500"
              frameBorder="0"
              sandbox="allow-popups allow-popups-to-escape-sandbox allow-same-origin allow-scripts"
            ></iframe>
          </AnimateOnIntersect>
        </>
      }
    />
  );
}
