import { cloneElement, PropsWithChildren } from "react";
import { CSSProps, UIColors } from "../../lib/types/generic";

interface Props extends CSSProps {
  color?: UIColors;
  variant?: "contained" | "outlined" | "flat";
  size?: "extra-small" | "small" | "medium" | "large" | "extra-large";
  startIcon?: JSX.Element;
  endIcon?: JSX.Element;
  iconSizeOverride?: number;
  type?: "button" | "submit" | "reset";
  disabled?: boolean;
  openInNewTab?: boolean;
  form?: string;
  href?: string;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

export default function Button(props: PropsWithChildren<Props>) {
  const {
    children,
    color = "black",
    variant = "contained",
    size = "medium",
    endIcon,
    startIcon,
    iconSizeOverride,

    type = "button",
    disabled = false,
    form,
    openInNewTab,
    href,
    onClick,

    style,
    className = "",
  } = props;

  let iconSize = {
    "extra-small": 16,
    small: 16,
    medium: 18,
    large: 24,
    "extra-large": 38,
  }[size];

  if (iconSizeOverride != null) iconSize = iconSizeOverride;

  const inner = (
    <div
      className="button-inner"
      style={{
        justifyContent: endIcon || startIcon ? "space-between" : "center",
      }}
    >
      {startIcon &&
        cloneElement(startIcon, {
          style: { paddingRight: 8, fontSize: iconSize },
          className: `${color} ${variant}`,
        })}
      <span>{children}</span>
      {endIcon &&
        cloneElement(endIcon, {
          style: { paddingLeft: 8, fontSize: iconSize },
          className: `${color} ${variant}`,
        })}
    </div>
  );

  if (href == null || disabled) {
    return (
      <button
        type={type}
        disabled={disabled}
        form={form}
        onClick={onClick}
        style={style}
        className={`button ${
          disabled ? "disabled" : ""
        } ${color} ${variant} ${size} ${className}`}
      >
        {inner}
      </button>
    );
  } else {
    return (
      <a
        style={style}
        className={`button ${
          disabled ? "disabled" : ""
        } ${color} ${variant} ${size} ${className}`}
        href={href}
        {...(openInNewTab
          ? {
              target: "_blank",
              rel: "noopener noreferrer",
            }
          : {})}
      >
        {inner}
      </a>
    );
  }
}
