import React, { useContext } from "react";
import { GlobalContext } from "../../GlobalContextProvider";

export default function TopProgress() {
  const { loading } = useContext(GlobalContext);

  if (!loading) return null;

  return (
    <div className="top-progress">
      <div className="inner"></div>
    </div>
  );
}
