import React from "react";
import { CSSProps, UIColors } from "../../lib/types/generic";
import AnimateOnIntersect, {
  IntersectionAnimationProps,
} from "./AnimateOnIntersect";

interface Props extends CSSProps {
  color?: UIColors;
  animationProps?: IntersectionAnimationProps;
}

export default function Square(props: Props) {
  const {
    color = "light-purple",
    className = "",
    style,
    id,
    animationProps,
  } = props;

  return (
    <AnimateOnIntersect {...animationProps}>
      <svg
        width="53"
        height="53"
        viewBox="0 0 53 53"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={`square ${className}`}
        style={style}
        id={id}
      >
        <rect
          x="0.723633"
          y="0.644531"
          width="52"
          height="52"
          className={`fill-${color}`}
        />
      </svg>
    </AnimateOnIntersect>
  );
}
