import React from "react";
import Bar from "./Bar";

import profileImage from "../../media/images/profile.jpg";
import calendarImage from "../../media/images/calendar.png";
import sessionImage from "../../media/images/session.png";
import CircularBlur from "../../components/common/CircularBlur";
import AnimateOnIntersect from "../../components/common/AnimateOnIntersect";

export default function InfoRows() {
  return (
    <div className="info-rows">
      <Bar
        className="profile-bar"
        left={
          <AnimateOnIntersect>
            <div>
              <h3 className="title text-gradient">Register</h3>
              <p className="text">
                Create your profile, select the games you play and the gaming
                system you use.
              </p>
            </div>
          </AnimateOnIntersect>
        }
        right={
          <AnimateOnIntersect>
            <img src={profileImage} alt="Profile" />
          </AnimateOnIntersect>
        }
      />

      <Bar
        left={
          <>
            <AnimateOnIntersect animation="slide-left">
              <img src={calendarImage} alt="Calendar" />
            </AnimateOnIntersect>

            <CircularBlur />
          </>
        }
        right={
          <AnimateOnIntersect animation="slide-left">
            <div>
              <h3 className="title text-gradient">Plan</h3>
              <p className="text">
                Select the times you are available, the number of spaces in each
                session and the price per person.
              </p>
            </div>
          </AnimateOnIntersect>
        }
      />

      <Bar
        left={
          <AnimateOnIntersect>
            <div>
              <h3 className="title text-gradient">Notify</h3>
              <p className="text">
                Tell your fans, wait for them to book in and let's get gaming!
                <br />
                <br />
                We handle all the payments and make a payout directly to your
                bank account each month.
              </p>
            </div>
          </AnimateOnIntersect>
        }
        right={
          <AnimateOnIntersect>
            <img src={sessionImage} alt="Session" />
          </AnimateOnIntersect>
        }
      />
    </div>
  );
}
