import React from "react";
import Banner from "../../components/common/Banner";
import CircularBlur from "../../components/common/CircularBlur";

export default function Header() {
  return (
    <header className="header">
      <Banner />

      <CircularBlur />
    </header>
  );
}
