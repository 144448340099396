import React, { useContext } from "react";
import { IoArrowUp } from "react-icons/io5";
import { GlobalContext } from "../../GlobalContextProvider";
import useScrollPosition from "../../hooks/useScrollPosition";
import IconButton from "../form/IconButton";

export default function ScrollToTop() {
  const { y } = useScrollPosition();
  const { breakpoints } = useContext(GlobalContext);

  return (
    <IconButton
      className={`scroll-to-top ${y > 0 ? "" : "hidden"}`}
      icon={<IoArrowUp />}
      color="light-black"
      size={
        breakpoints.ul ? (breakpoints.sl ? "medium" : "large") : "extra-large"
      }
      variant="contained"
      onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
    />
  );
}
