import React from "react";
import CircularBlur from "../../components/common/CircularBlur";
import InfoRows from "./InfoRows";
import Containers from "./Containers";
import DoNext from "./DoNext";
import SupportedGames from "./SupportedGames";
import TalentWanted from "./TalentWanted";
import AnimateOnIntersect from "../../components/common/AnimateOnIntersect";

export default function Content() {
  return (
    <div className="content">
      <div className="top-text-wrapper">
        <CircularBlur />

        <AnimateOnIntersect>
          <div>
            <p className="top-text title">How does it work?</p>
            <p className="top-text">
              <span>
                The gaming platform that allows you to{" "}
                <span className="text-gradient">connect</span> with your
                followers, <span className="text-gradient">increase</span> your
                community engagement and{" "}
                <span className="text-gradient">earn</span> while doing so!
              </span>
            </p>
          </div>
        </AnimateOnIntersect>
      </div>

      <div className="bars">
        <InfoRows />
        <Containers />
        <SupportedGames />
        <TalentWanted />
        <DoNext />
      </div>
    </div>
  );
}
