import React, { HTMLProps } from "react";

interface Props {
  color?: "white" | "black" | "purple" | "orange" | "light-blue" | "yellow";
  size?: "small" | "medium" | "large";
  svgProps?: HTMLProps<SVGSVGElement>;
  disabled?: boolean;
}

export default function Banner(props: Props) {
  const { color = "white", size = "medium", svgProps = {} } = props;

  const width = {
    small: 100,
    medium: 125,
    large: 200,
  }[size];

  return (
    <svg
      {...(svgProps as any)}
      className={`banner fill-${color} ${svgProps.className || ""}`}
      width={width}
      viewBox="0 0 248 82"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M50.9662 16.8986L26.6553 80.375C26.5337 80.6898 26.3199 80.9606 26.0419 81.1517C25.7638 81.3429 25.4344 81.4456 25.097 81.4463H14.7966C14.5258 81.4463 14.2591 81.3803 14.0194 81.2542C13.7798 81.128 13.5744 80.9455 13.421 80.7223C13.2676 80.4991 13.1709 80.2419 13.139 79.9729C13.1072 79.704 13.1413 79.4313 13.2384 79.1785L19.4158 63.0439C18.0441 63.043 16.7048 62.627 15.5741 61.8505C14.4433 61.0741 13.5741 59.9735 13.0807 58.6937L1.09217 27.5932C0.536724 26.1505 0.340739 24.5943 0.521137 23.0589C0.701535 21.5236 1.25289 20.0552 2.12762 18.7805C3.00234 17.5059 4.17414 16.4633 5.54186 15.7427C6.90958 15.0222 8.43212 14.6453 9.97804 14.6447V14.6447L24.0118 51.0554L35.6061 20.7618C36.2961 18.9612 37.5163 17.4125 39.1053 16.3202C40.6944 15.228 42.5776 14.6437 44.5058 14.6447H49.4033C49.6732 14.6437 49.9392 14.7085 50.1786 14.8332C50.4179 14.958 50.6233 15.1391 50.7771 15.3609C50.9309 15.5827 51.0285 15.8385 51.0614 16.1064C51.0943 16.3743 51.0617 16.6461 50.9662 16.8986Z" />
      <path d="M90.4186 21.6663C90.4247 19.7871 91.1746 17.9868 92.5042 16.6589C93.8338 15.331 95.6351 14.5835 97.5143 14.5798H101.224C101.839 14.5798 102.429 14.8241 102.864 15.259C103.299 15.6939 103.543 16.2837 103.543 16.8987V61.3373C103.543 61.7801 103.367 62.2048 103.054 62.5179C102.741 62.831 102.317 63.0069 101.874 63.0069H92.0742C91.6314 63.0069 91.2068 62.831 90.8937 62.5179C90.5806 62.2048 90.4047 61.7801 90.4047 61.3373V56.2358H89.9919C86.7177 60.4468 81.1802 62.993 72.0996 62.993C51.7864 62.993 49.5 45.3001 49.5 38.7794C49.5 31.7208 51.6612 14.5659 72.1367 14.5659C81.0875 14.5659 86.5693 17.3856 89.8528 21.6755L90.4186 21.6663ZM89.5838 38.7702C89.5838 32.2171 87.2649 25.8448 75.9303 25.8448C63.3575 25.8448 62.0172 35.0043 62.0172 38.7702C62.0172 42.2485 63.4085 51.6955 75.8932 51.6955C87.2649 51.6816 89.5838 46.1163 89.5838 38.7702Z" />
      <path d="M140.357 34.8049L160.731 62.9837H145.426C145.109 62.9847 144.797 62.9099 144.514 62.7655C144.232 62.6211 143.988 62.4113 143.803 62.1535L130.818 44.1731L121.394 53.4486V61.1796C121.394 61.6224 121.218 62.0471 120.905 62.3602C120.592 62.6733 120.167 62.8492 119.724 62.8492H109.985C109.542 62.8492 109.118 62.6733 108.804 62.3602C108.491 62.0471 108.315 61.6224 108.315 61.1796V10.1879C108.316 8.93619 108.563 7.69685 109.043 6.54075C109.523 5.38464 110.226 4.33445 111.112 3.4502C111.998 2.56595 113.05 1.86498 114.207 1.38737C115.364 0.90976 116.603 0.664869 117.855 0.666697H119.71C119.93 0.666697 120.147 0.709882 120.349 0.793786C120.552 0.87769 120.736 1.00067 120.891 1.15571C121.046 1.31074 121.169 1.49479 121.253 1.69736C121.337 1.89992 121.38 2.11702 121.38 2.33628V35.6351L139.931 17.33C141.716 15.5646 144.126 14.5761 146.637 14.5798H160.912L140.357 34.8049Z" />
      <path d="M189.031 34.8049L207.489 60.3356C207.669 60.5846 207.777 60.8785 207.801 61.1849C207.825 61.4913 207.763 61.7984 207.624 62.0722C207.484 62.346 207.272 62.576 207.01 62.7369C206.748 62.8977 206.447 62.9831 206.14 62.9837H194.193C193.852 62.9837 193.517 62.9031 193.214 62.7483C192.911 62.5935 192.649 62.369 192.449 62.0932L179.496 44.1731L170.072 53.4485V61.3327C170.072 61.5519 170.029 61.769 169.945 61.9716C169.861 62.1741 169.738 62.3582 169.583 62.5132C169.428 62.6683 169.244 62.7912 169.041 62.8752C168.839 62.9591 168.622 63.0022 168.402 63.0022H159.159C158.588 63.0022 158.04 62.7757 157.636 62.3722C157.232 61.9688 157.004 61.4215 157.003 60.8503V10.1879C157.004 7.66231 158.008 5.24055 159.795 3.45511C161.581 1.66967 164.003 0.666687 166.529 0.666687L167.92 0.666687C168.491 0.666687 169.038 0.893404 169.442 1.29696C169.845 1.70052 170.072 2.24787 170.072 2.81859V35.6351L188.623 17.33C190.403 15.5691 192.806 14.581 195.31 14.5798H207.726C207.877 14.5798 208.024 14.6247 208.15 14.7088C208.275 14.793 208.373 14.9126 208.43 15.0523C208.487 15.1921 208.501 15.3458 208.471 15.4937C208.441 15.6417 208.367 15.7772 208.259 15.883L189.031 34.8049Z" />
      <path d="M247.058 17.3625L245.523 26.4385C245.487 26.6582 245.407 26.8683 245.288 27.0565C245.169 27.2446 245.014 27.4069 244.831 27.5337C244.647 27.6605 244.441 27.7491 244.223 27.7944C244.005 27.8397 243.78 27.8407 243.562 27.7973C241.249 27.3377 238.898 27.1047 236.54 27.1017C226.801 27.1017 223.898 34.6982 223.898 41.256V61.3002C223.898 61.743 223.722 62.1677 223.409 62.4808C223.096 62.7939 222.671 62.9698 222.228 62.9698H212.433C212.214 62.9698 211.997 62.9266 211.794 62.8427C211.592 62.7588 211.408 62.6358 211.253 62.4808C211.098 62.3257 210.975 62.1417 210.891 61.9391C210.807 61.7365 210.764 61.5194 210.764 61.3002V24.1057C210.764 21.5817 211.765 19.1609 213.549 17.3748C215.332 15.5888 217.752 14.5835 220.276 14.5798H222.219C222.438 14.5798 222.655 14.623 222.858 14.7069C223.06 14.7908 223.244 14.9138 223.399 15.0688C223.554 15.2239 223.677 15.4079 223.761 15.6105C223.845 15.8131 223.888 16.0302 223.888 16.2494V21.6941H224.709C227.404 17.0564 230.39 14.5798 238.711 14.5798C241.094 14.606 243.466 14.9016 245.783 15.461C246.199 15.5547 246.562 15.804 246.799 16.1577C247.037 16.5114 247.129 16.9425 247.058 17.3625V17.3625Z" />
    </svg>
  );
}
