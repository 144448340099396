import React, { useContext } from "react";
import AnimateOnIntersect from "../../components/common/AnimateOnIntersect";
import CircularBlur from "../../components/common/CircularBlur";
import Square from "../../components/common/Square";
import Button from "../../components/form/Button";
import { GlobalContext } from "../../GlobalContextProvider";
import { applyUrl } from "../../lib/constants";

import microphoneImg from "../../media/images/microphone.png";
import speechBubble from "../../media/images/speech_bubble.png";
import Panel from "./Panel";

export default function TopBanner() {
  const { breakpoints } = useContext(GlobalContext);

  return (
    <div className="top-banner">
      <div className="left">
        <Square
          color="light-purple"
          animationProps={{
            animation: "slide-down",
            transitionTimeout: 300,
          }}
        />
        <Square
          color="light-blue"
          animationProps={{
            animation: "slide-down",
            transitionTimeout: 600,
          }}
        />
        <Square
          color="light-purple"
          animationProps={{
            animation: "slide-down",
            transitionTimeout: 900,
          }}
        />

        <CircularBlur />

        <AnimateOnIntersect>
          <h1 className="title">
            <span className="game-wrapper">
              <span className="text-gradient animate">Game</span>
              <div className="speech-bubble-wrapper">
                <img
                  src={speechBubble}
                  alt="speech bubble"
                  className="speech-bubble"
                />
              </div>
            </span>
            with your
            <br />
            community
          </h1>
        </AnimateOnIntersect>
        <AnimateOnIntersect>
          <h2 className="sub-title">
            <Button
              color="white"
              size={breakpoints.sm ? "medium" : "large"}
              href={applyUrl}
              openInNewTab
            >
              Apply now
            </Button>
            Join the waiting list now for
            <br />
            exclusive first access
            <div className="microphone-wrapper">
              <img
                src={microphoneImg}
                alt="microphone"
                className="microphone"
              />
            </div>
          </h2>
        </AnimateOnIntersect>
      </div>

      <AnimateOnIntersect animation="slide-left">
        <div className="right">
          <Panel />
        </div>
      </AnimateOnIntersect>
    </div>
  );
}
