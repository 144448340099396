import { useLayoutEffect } from "react";

const useIntersection = (
  element?: HTMLElement | null,
  onIntersect?: IntersectionObserverCallback,
  options?: IntersectionObserverInit
) => {
  useLayoutEffect(() => {
    if (element == null || onIntersect == null) return;
    const observer = new IntersectionObserver(onIntersect, options);
    observer.observe(element);
    return () => observer.unobserve(element);
  }, [element, onIntersect, options]);
};

export default useIntersection;
