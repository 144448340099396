import { CSSProperties } from "react";
import { IconType } from "react-icons/lib";

export enum CIG {
  Gamer = "GAMER",
  Celebrity = "CELEBRITY",
  Influencer = "INFLUENCER",
}

export enum Platform {
  Facebook = "FACEBOOK",
  OnlyFans = "ONLYFANS",
  Twitter = "TWITTER",
  Twitch = "TWITCH",
  Instagram = "INSTAGRAM",
  YouTube = "YOUTUBE",
  TikTok = "TIKTOK",
}

export type PlatformInfo = {
  key: Platform;
  name: string;
  icon: IconType;
  color: string;
};

export type UIColors =
  | "white"
  | "dark-white"
  | "extra-light-gray"
  | "light-gray"
  | "gray"
  | "dark-gray"
  | "light-black"
  | "black"
  | "purple"
  | "light-purple"
  | "orange"
  | "yellow"
  | "light-blue"
  | "discord-blue"
  | "youtube-red"
  | "tiktok-black"
  | "facebook-blue"
  | "instagram-orange"
  | "google-red"
  | "red"
  | "green";

export interface CSSProps {
  style?: CSSProperties;
  className?: string;
  id?: string;
}

export interface Notification {
  key?: string;
  type?: "info" | "success" | "warn" | "error";
  text: string;
  timeout?: number;
}
