import React from "react";
import AnimateOnIntersect from "../../components/common/AnimateOnIntersect";
import CircularBlur from "../../components/common/CircularBlur";
import Bar from "./Bar";

export default function Containers() {
  return (
    <Bar
      className="containers"
      left={
        <AnimateOnIntersect>
          <div className="container">
            <div className="wrapper">
              <h3 className="title">For the Gamers</h3>
              <ul>
                <li>Organise your community sessions</li>
                <li>
                  Charge a fee, run them for free or raise money for charity
                </li>
                <li>Activate Yakkr+ to give benefits</li>
                <li>Run the sessions on or off stream </li>
                <li>Allows busy fans to stay involved outside of streams</li>
              </ul>
            </div>
          </div>
        </AnimateOnIntersect>
      }
      right={
        <>
          <AnimateOnIntersect animation="slide-left">
            <div className="container">
              <div className="wrapper">
                <h3 className="title">For the Celebrities and Influencers</h3>
                <ul>
                  <li>Game with your fans</li>
                  <li>
                    Charge a fee, run them for free or raise money for charity{" "}
                  </li>
                  <li>Activate Yakkr+ to give benefits</li>
                  <li>Enjoy a laugh in a safe environment</li>
                  <li>A life experience for fans </li>
                </ul>
              </div>
            </div>
          </AnimateOnIntersect>
          <CircularBlur />
        </>
      }
    />
  );
}
