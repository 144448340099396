import React from "react";
import AnimateOnIntersect from "../../components/common/AnimateOnIntersect";
import CircularBlur from "../../components/common/CircularBlur";

import wordCloud from "../../media/images/word_cloud.svg";

export default function TalentWanted() {
  return (
    <div className="talent-wanted">
      <AnimateOnIntersect animation="fade">
        <p className="title">
          <span className="text-gradient">Talent</span> that we want
        </p>
      </AnimateOnIntersect>
      <AnimateOnIntersect animation="fade">
        <div className="word-cloud-wrapper">
          <CircularBlur />
          <img src={wordCloud} alt="Word Cloud" className="word-cloud" />
        </div>
      </AnimateOnIntersect>
    </div>
  );
}
