import React, {
  Children,
  cloneElement,
  isValidElement,
  PropsWithChildren,
  RefObject,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import useIntersection from "../../hooks/useIntersection";

interface Props {
  onIntersect: IntersectionObserverCallback;
  nodeRef?: RefObject<HTMLElement | null>;
  useParent?: boolean;
}

export default function Intersection(props: PropsWithChildren<Props>) {
  const { children, onIntersect, nodeRef, useParent } = props;

  const [element, setElement] = useState<HTMLElement | null>(null);

  const defaultRef = useRef<HTMLDivElement | null>(null);

  const ref = nodeRef ?? defaultRef;

  useIntersection(useParent ? element?.parentElement : element, onIntersect);
  useLayoutEffect(() => setElement(ref.current), [ref]);

  return (
    <>
      {Children.map(
        children,
        (child) =>
          isValidElement(child) &&
          cloneElement(child, {
            ref: defaultRef,
          })
      )}
    </>
  );
}
