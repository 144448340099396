import React from "react";
import { CSSProps } from "../../lib/types/generic";
import circularBlur from "../../media/images/circular_blur.png";
import AnimateOnIntersect from "./AnimateOnIntersect";

interface Props extends CSSProps {}

const CircularBlur = (props: Props) => {
  const { className = "", style, id } = props;

  return (
    <AnimateOnIntersect animation="fade" transitionTimeout={1000}>
      <img
        className={`circular-blur ${className}`}
        style={style}
        id={id}
        alt="circular blur"
        src={circularBlur}
      />
    </AnimateOnIntersect>
  );
};

export default CircularBlur;
