import React, { useContext } from "react";
import Banner from "../../components/common/Banner";
import IconButton from "../../components/form/IconButton";

import { BsDiscord } from "react-icons/bs";
import {
  IoLogoFacebook,
  IoLogoInstagram,
  IoLogoTiktok,
  IoLogoTwitter,
} from "react-icons/io5";
import Button from "../../components/form/Button";
import {
  applyUrl,
  businessEmail,
  contactEmail,
  discordInvite,
  facebookUrl,
  instagramUrl,
  privacyUrl,
  tiktokUrl,
  twitterUrl,
} from "../../lib/constants";
import { GlobalContext } from "../../GlobalContextProvider";

export default function Footer() {
  const { breakpoints } = useContext(GlobalContext);

  const buttonSize = breakpoints.sl
    ? breakpoints.xl
      ? breakpoints.sm
        ? "extra-small"
        : "small"
      : "medium"
    : "extra-large";

  const iconSize = breakpoints.sl
    ? breakpoints.xl
      ? "small"
      : "medium"
    : "large";

  return (
    <div className="footer">
      <div className="banner-wrapper">
        <Banner size="small" />
      </div>
      <div className="links">
        <Button
          href={`mailto:${contactEmail}`}
          size={buttonSize}
          variant="flat"
          color="white"
        >
          Contact us
        </Button>
        <Button
          href={applyUrl}
          openInNewTab
          size={buttonSize}
          variant="flat"
          color="white"
        >
          Become a Creator
        </Button>
        <Button
          href={privacyUrl}
          openInNewTab
          size={buttonSize}
          variant="flat"
          color="white"
        >
          Privacy Policy
        </Button>
        <Button
          href={`mailto:${businessEmail}`}
          size={buttonSize}
          variant="flat"
          color="white"
        >
          Investor Interest
        </Button>
      </div>
      <div className="icons">
        <IconButton
          variant="flat"
          color="white"
          icon={<BsDiscord />}
          href={discordInvite}
          openInNewTab
          size={iconSize}
        />
        <IconButton
          variant="flat"
          color="white"
          icon={<IoLogoTwitter />}
          href={twitterUrl}
          openInNewTab
          size={iconSize}
        />
        <IconButton
          variant="flat"
          color="white"
          icon={<IoLogoInstagram />}
          href={instagramUrl}
          openInNewTab
          size={iconSize}
        />
        <IconButton
          variant="flat"
          color="white"
          icon={<IoLogoFacebook />}
          href={facebookUrl}
          size={iconSize}
        />
        <IconButton
          variant="flat"
          color="white"
          icon={<IoLogoTiktok />}
          href={tiktokUrl}
          openInNewTab
          size={iconSize}
        />
      </div>
    </div>
  );
}
