import {
  SiTwitter,
  SiTwitch,
  SiYoutube,
  SiTiktok,
  SiFacebook,
  SiInstagram,
} from "react-icons/si";
import { CIG, Platform, PlatformInfo, UIColors } from "./types/generic";

export const sicColors: Record<CIG, UIColors> = {
  GAMER: "purple",
  CELEBRITY: "orange",
  INFLUENCER: "light-blue",
};

export const sicPlatforms: { [K in CIG]: Platform[] } = {
  GAMER: [Platform.Twitch, Platform.YouTube, Platform.Facebook],
  CELEBRITY: [
    Platform.Facebook,
    Platform.Instagram,
    Platform.TikTok,
    Platform.Twitter,
    Platform.YouTube,
  ],
  INFLUENCER: [
    Platform.Facebook,
    Platform.Instagram,
    Platform.TikTok,
    Platform.Twitter,
    Platform.YouTube,
  ],
};

export const platformInfoSet: PlatformInfo[] = [
  {
    key: Platform.Twitter,
    name: "Twitter",
    icon: SiTwitter,
    color: "twitter-blue",
  },
  {
    key: Platform.Twitch,
    name: "Twitch",
    icon: SiTwitch,
    color: "purple",
  },
  {
    key: Platform.YouTube,
    name: "YouTube",
    icon: SiYoutube,
    color: "youtube-red",
  },
  {
    key: Platform.TikTok,
    name: "TikTok",
    icon: SiTiktok,
    color: "tiktok-black",
  },
  {
    key: Platform.Facebook,
    name: "Facebook",
    icon: SiFacebook,
    color: "facebook-blue",
  },
  {
    key: Platform.Instagram,
    name: "Instagram",
    icon: SiInstagram,
    color: "instagram-orange",
  },
];
export const contactEmail = "info@yakkr.co.uk";
export const businessEmail = "marcus@yakkr.co.uk";
export const discordInvite = "https://discord.gg/34zyGF4KGN";
export const icoWebsite = "https://www.ico.org.uk";
export const yakkrExclusiveWebsite = "www.exclusive.yakkr.co.uk ";

export const twitterUrl = "https://twitter.com/Yakkr_";
export const instagramUrl = "https://www.instagram.com/yakkr_/";
export const facebookUrl = "https://www.facebook.com/Yakkr.co.uk";
export const tiktokUrl = "https://www.tiktok.com/@yakkr_";

export const mainSiteUrl = "https://yakkr.co.uk/";
export const applyUrl = `${mainSiteUrl}register/splash`;

export const helpSiteUrl = "https://community.yakkr.co.uk/";
export const privacyUrl = `${helpSiteUrl}privacypolicy`;
export const termsUrl = `${helpSiteUrl}terms-of-service`;
