import React from "react";
import Header from "./header/Header";
import TopBanner from "./topBanner/TopBanner";
import Content from "./content/Content";
import Footer from "./footer/Footer";
import ScrollToTop from "../components/common/ScrollToTop";

export default function Home() {
  return (
    <div id="home-page">
      <Header />

      <TopBanner />

      <Content />

      <Footer />

      <ScrollToTop />
    </div>
  );
}
