import { Dayjs } from "dayjs";
import { ComponentProps, JSXElementConstructor, ReactElement } from "react";
import { isDev } from "../../config/config";
import { CIG } from "../types/generic";

const awaitingStrings = [
  "Hold up",
  "Hold on",
  "Please hold",
  "Please wait",
  "Awaiting",
  "One second",
  "Uno momento",
  "We loading",
  "Loading...",
  "We waiting",
];

export function randomAwaitingString() {
  return awaitingStrings[Math.floor(Math.random() * awaitingStrings.length)];
}

export function getCIGColor(type: CIG) {
  const types = {
    GAMER: "purple",
    CELEBRITY: "yellow",
    INFLUENCER: "light-blue",
  };
  return types[type] as "purple" | "yellow" | "light-blue" | undefined;
}

export function rangeAsArray(start: number, end: number) {
  const array = [] as number[];
  for (let i = start; i <= end; i++) {
    array.push(i);
  }
  return array;
}

export function daysInMonth(date: Dayjs, minDate: Dayjs, maxDate: Dayjs) {
  let days = date.daysInMonth();

  if (date.month() === minDate.month() && date.year() === minDate.year()) {
    days = minDate.daysInMonth() - minDate.date();
  }

  if (date.month() === maxDate.month() && date.year() === maxDate.year()) {
    days = maxDate.date();
  }

  return days;
}

export function monthsInYear(date: Dayjs, minDate: Dayjs, maxDate: Dayjs) {
  let months = 12;

  if (date.year() === minDate.year()) {
    months = 11 - minDate.month() + 1;
  }

  if (date.year() === maxDate.year()) {
    months = maxDate.month() + 1;
  }

  return months;
}

export function nodeIsElement(node: any): node is ReactElement {
  if (node == null) return false;
  if (typeof node !== "object") return false;
  return node.type != null;
}

export function elementInstanceOf<E extends JSXElementConstructor<any>>(
  element: ReactElement,
  ofElement: E
): element is ReactElement<ComponentProps<E>> {
  return element.type === ofElement;
}

export function elementIsTag<E extends HTMLElement>(
  element: HTMLElement,
  tag: string
): element is E {
  if (element == null) return false;
  return element.tagName === tag.toUpperCase();
}

export function enumContains<T>(enumType: any, value: T): value is T {
  return Object.values(enumType).includes(value);
}

export function formatTimeElapsed(ms: number) {
  const seconds = (ms / 1000) % 60;
  const minutes = Math.floor(ms / 60000) % 60;
  const hours = Math.floor(ms / 3600000);
  return `${hours > 0 ? `${hours}:` : ""}${minutes}:${seconds
    .toString()
    .padStart(2, "0")}`;
}

export function generateUuid() {
  const S4 = function () {
    return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
  };
  return (
    S4() +
    S4() +
    "-" +
    S4() +
    "-" +
    S4() +
    "-" +
    S4() +
    "-" +
    S4() +
    S4() +
    S4()
  );
}

export const log = (...text: any[]) => {
  if (isDev) console.log("[Development]", ...text);
};
